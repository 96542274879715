<template>
  <div>
    <mx-preloader :loader-status="preloader"/>
    <v-tabs
        v-model="year_index"
        background-color="#cecece"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab :disabled="item.disabled" v-for="item in years" :key="item.name" @click="getActiveYear(item.name)">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="year_index">
      <v-tab-item v-for="year in years" :key="year.name">
        <div v-if="!months_preloader">
          <v-tabs
              v-model="month_index"
              background-color="#f1f1f1"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              grow
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab :disabled="item.disabled" v-for="item in months" :key="item.name" @click="getActiveMonth(item)">
              {{ item.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="month_index">
            <v-tab-item v-for="item in months" :key="item.name">
              <v-card v-if="!report_preloader" class="pt-6" color="basil" flat>
                <ReportList :reports="active_reports" :companies="companies"/>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <div v-else style="height: calc(100vh - 100px)" class="d-flex align-center justify-center">
          <h2>{{ $t('report.not_reports') }}</h2>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import ReportList from "@/components/Report/ReportList";

export default {
  components: {ReportList},
  data() {
    return {
      preloader: true,
      report_preloader: true,
      months_preloader: true,

      reports: [],
      active_reports: [],
      companies: [],

      // Year
      active_year: null,
      current_year_number: null,
      year_index: 0,
      years: [],

      // Month
      active_month: null,
      month_index: 1,
      months: [],
    }
  },
  created() {
    this.byCreate()
  },
  methods: {
    mxPreloader() {
      this.preloader = false
    },
    byCreate() {
      this.$store.dispatch('reportFromEmployee', this.$store.getters.user.id).then(() => {
        // Load
        this.loadCompanies()
        this.loadReports()

        // Year
        this.getAllYears()
        this.getCurrentYear()

        // Month
        this.getAllMonths()
        this.getCurrentMonth()

        // Preload
        this.mxPreloader()
      })
    },

    /** Year **/
    getAllYears() {
      const years = Object.keys(this.reports)
      for (let i in years) {
        this.years.push({name: parseInt(years[i]), disabled: false})
      }
      let date = new Date()
      this.years.push({name: parseInt(date.getFullYear() + 1), disabled: true})
    },
    getCurrentYear() {
      let date = new Date()
      this.current_year_number = date.getFullYear()
      this.year_index = this.years.findIndex(i => i.name === date.getFullYear())
      this.getActiveYear(date.getFullYear())
    },
    getActiveYear(year) {
      if (this.reports[year] === undefined) {
        this.active_year = 0
        this.getAllMonths()
        this.firstMonth()
      } else {
        this.active_year = this.reports[year]
        this.getAllMonths()

        if(year !== this.current_year_number) {
          this.firstMonth()
        } else {
          this.getCurrentMonth()
        }
      }
    },

    /** Month **/
    getAllMonths() {
      const months = [
        {id: 0, month_number: '01', name: 'Januar', disabled: true},
        {id: 1, month_number: '02', name: 'Februar', disabled: true},
        {id: 2, month_number: '03', name: 'März', disabled: true},
        {id: 3, month_number: '04', name: 'April', disabled: true},
        {id: 4, month_number: '05', name: 'Mai', disabled: true},
        {id: 5, month_number: '06', name: 'Juni', disabled: true},
        {id: 6, month_number: '07', name: 'Juli', disabled: true},
        {id: 7, month_number: '08', name: 'August', disabled: true},
        {id: 8, month_number: '09', name: 'September', disabled: true},
        {id: 9, month_number: '10', name: 'Oktober', disabled: true},
        {id: 10, month_number: '11', name: 'November', disabled: true},
        {id: 11, month_number: '12', name: 'Dezember', disabled: true},
      ]
      this.checkMonths(months)
    },
    getCurrentMonth() {
      if (this.active_year === 0) {
        this.active_month = 0
      } else {
        let date = new Date()
        this.month_index = this.months.findIndex(i => i.id === date.getMonth())
        this.active_month = this.months.find(i => i.id === date.getMonth())
        this.getActiveMonth(this.active_month)
      }
    },
    getActiveMonth(month) {
      if (month === null || month === undefined) {
        this.active_reports = []
      } else  {
        this.active_reports = this.active_year[month.month_number]
      }
      setTimeout(() => {
        this.report_preloader = false
      }, 300)
    },
    checkMonths(months) {
      this.months_preloader = true
      if (this.active_year === 0) {
        this.months =  months
      } else {
        for (let mon in this.active_year) {
          this.months = months.map(month => {
            if (month.month_number === mon) {
              month.disabled = false
            }
            return month
          })
        }
        this.months_preloader = false
      }
    },
    firstMonth() {
      this.months_preloader = true
      let month = this.months.find(mont => mont.disabled === false)

      if (month === undefined) {
        this.month_index = 0
        this.getActiveMonth(null)
      } else {
        this.month_index = month.id
        this.getActiveMonth(month)
      }
      this.months_preloader = false
    },

    /** Load **/
    loadCompanies() {
      this.companies = this.$store.getters.companies_for_reports
    },
    loadReports() {
      this.reports = this.$store.getters.reports_from_employee
    },
  }
}
</script>
