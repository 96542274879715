<template>
  <div style="height: calc(100vh - 48px)">
    <Report/>
  </div>
</template>

<script>

import Report from "@/components/Report/Report";

export default {
  components: {Report},

}
</script>
